
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";

import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
import {
  PHONE,
  GST,
  COUNTRYCODE,
  ZIPCODE,
  STATE,
  CITY,
  ADDRESS1,
  ADDRESS2,
  COB,
  CIN,
  PAN,
  COMPANYNAME,
  ID,
  INDUSTRY,
  DOMAIN,
} from "@/helper/globalConst";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  name: "add-customer-modal",
  components: {
    Form,
    Field,
  },
  mixins: [Globals],
  data() {
    return {
      radio: "",
      role: this.getOrgRole(),
      orgrole: "",
      orgType: "",
      loading: false,
      buttonActive: false,
      ips: [],
      domains: [],
      ip_error: "",
      domain_error: "",
    };
  },
  methods: {
    validate_ips(inputValue) {
      const regexPattern =
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
      return regexPattern.test(inputValue);
    },
    validate_domain(inputValue) {
      const regexPattern = /^(?:[a-z0-9-]{1,63}\.)+[a-z]{2,6}$/;
      return regexPattern.test(inputValue);
    },
    addIP(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.ips.length >= 1) {
          for (let i = 0; i < this.ips.length; i++) {
            if (this.ips[i] === val) {
              return false;
            }
          }
        }
        if (this.validate_ips(val)) {
          this.ips.push(val);
          event.target.value = "";
          this.formData.data["allowedIP"] = this.ips.join(",");
          this.ip_error = "";
        } else {
          this.ip_error = "Invalid IP.";
          return false;
        }

      }
    },
    removeIP(index) {
      this.ips.splice(index, 1);
      this.formData.data["allowedIP"] = this.ips.join(",");
    },
    removeLastIP(event) {
      if (event.target.value.length === 0) {
        this.removeIP(this.ips.length - 1);
      }
    },
    addDomain(event) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (this.domains.length >= 1) {
          for (let i = 0; i < this.domains.length; i++) {
            if (this.domains[i] === val) {
              return false;
            }
          }
        }
        if (this.validate_domain(val)) {
          this.domains.push(val);
          event.target.value = "";
          this.formData.data["allowedDomain"] = this.domains.join(",");
          this.domain_error = "";
        } else {
          this.domain_error = "Invalid Domain Name.";
          return false;
        }
      }
    },
    removeDomain(index) {
      this.domains.splice(index, 1);
      this.formData.data["allowedDomain"] = this.domains.join(",");
    },
    removeLastDomain(event) {
      if (event.target.value.length === 0) {
        this.removeDomain(this.domains.length - 1);
      }
    },

    resetform() {
      (this.$refs["resetForm"] as any).click();
    },
    radioFun() {
      console.log("ASHJHJ", this.radio);
    },
    checkRole() {
      if (this.role == "consumer") this.role = "C";
      else if (this.role == "manufacturer") this.role = "M";
      else if (this.role == "api_provider") this.role = "P";
      else if (this.role == "1SB") this.role = "P";
      return this.role;
    },
    check() {
      if (this.orgType == "consumer") this.orgrole = "C";
      else if (this.orgType == "manufacturer") this.orgrole = "M";
      else if (this.orgType == "api_provider") this.orgrole = "P";
      else if (this.orgType == "1SB") this.orgrole = "P";
      return this.orgrole;
    },
    addOrganization(formData) {
      this.loading = true;
      this.buttonActive = true;
      formData.organizationType = this.checkRole();

      formData.organizationId = this.globalData.organizationId;
      formData.data.organizationType = this.check();
      ApiService.post("/organization", formData)
        .then((data) => {
          (this.loading = false),
            (this.buttonActive = false),
            (this.$refs["resetForm"] as any).click();
          hideModal(this.addCustomerModalRef);
          this.$emit("changeTitle", "false");
          ElNotification({
            title: "Success",
            message: "New Organization added!",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.buttonActive = false;
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        });
    },
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addCustomerModalRef = ref<null | HTMLElement>(null);

    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        id: "",
        name: "",
        gstNo: "",
        panNo: "",
        cinNo: "",
        contact: "",
        countryOfBusiness: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        teleCountryCode: "",
        email: "",
        mobileNumber: "",
        organizationType: "",
        industry: "",
        addressLine3: "",
      },
    });

    const formValidation = Yup.object().shape({
      orgType: Yup.string().label("Organization Type"),
      id: Yup.string()
        .max(8, "Id should be atmost 8 alphanumeric")
        .matches(ID, "Id must be only alphanumeric")
        .required()
        .label("Id"),
      companyName: Yup.string()
        .max(45, "Company name should be atmost 45 alphanumeric")
        .matches(COMPANYNAME, "Company name must be alphanumeric")
        .required()
        .label("Company name"),
      industry: Yup.string()
        .max(45, "Industry name should be atmost 45 alphanumeric")
        .matches(INDUSTRY, "Industry name should be alphanumeric")
        .required()
        .label("Industry name"),
      allowedDomains: Yup.string()
        .matches(DOMAIN, "Invalid Allowd Domain format. example.com")
        .label("Allowed Domains"),

      gstNumber: Yup.string()
        .min(15, "GST number should be exactly 15 alphanumeric")
        .max(15, "GST number should be exactly 15 alphanumeric")
        .matches(GST, "GST number must be alphanumeric")
        .required()
        .label("GST Number"),
      panNumber: Yup.string()
        .min(10, "PAN number should be exactly 10 alphanumeric")
        .max(10, "PAN number should be exactly 10 alphanumeric")
        .matches(PAN, "PAN number must be alphanumeric")
        .required()
        .label("PAN Number"),
      cinNumber: Yup.string()
        .min(21, "CIN number should be exactly 21 alphanumeric")
        .max(21, "CIN number should be exactly 21 alphanumeric")
        .matches(CIN, "CIN number must be alphanumeric")
        .required()
        .label("CIN Number"),
      contact: Yup.string()
        .min(10, "Contact number should be exactly 10 digits")
        .max(10, "Contact number should be exactly 10 digits")
        .matches(PHONE, "Mobile number must be only digits")
        .required()
        .label("Contact Number"),
      country: Yup.string()
        .max(3, "Country should be atmost 3 characters")
        .matches(
          COB,
          "Country code must be only upper case with max 3 characters"
        )
        .required()
        .label("Country"),
      address1: Yup.string()
        .max(45, "Address should be atmost 45 alphanumeric")
        .matches(ADDRESS1, "Address should be valid")
        .required()
        .label("Address"),
      address2: Yup.string()
        .max(45, "Address should be atmost 45 alphanumeric")
        .matches(ADDRESS2, "Address should be valid")
        .label("Address"),
      city: Yup.string()
        .max(45, "City should be atmost 45 characters")
        .matches(CITY, "City must be only characters")
        .required()
        .label("City"),
      state: Yup.string()
        .max(45, "State should be atmost 45 characters")
        .matches(STATE, "State must be only characters")
        .required()
        .label("State"),
      zipCode: Yup.string()
        .max(10, "ZIP code should be atmost 10 digits")
        .matches(ZIPCODE, "ZIP code must be only digits")
        .required()
        .label("ZIP code"),
      teleCountryCode: Yup.string()
        .max(10, "Country code should be atmost 10 digits")
        .matches(COUNTRYCODE, "Country code must be only digits")
        .required()
        .label("Country code"),
      email: Yup.string()
        .email("email must contain @ and . or invalid email address")
        .required()
        .label("Email"),
    });

    return {
      formData,
      formValidation,
      formRef,
      addCustomerModalRef,
    };
  },
});
