
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { __values } from "tslib";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { Field, Form } from "vee-validate";
import { NAME, PHONE } from "@/helper/globalConst";
import { ElNotification } from "element-plus";
import * as Yup from "yup";

export default defineComponent({
  name: "AddAdminUserModel",
  mixins: [Globals],

  components: {
    Form,
    Field,
  },
  props: {
    render: Boolean,
  },
  data() {
    return {
      requestbody: null,
      orgId: "",
      orgType:"",
      formflag: false,
      role: this.getOrgRole(),
      flag: false,
      reset: false,
      loading:false,
      buttonActive:false
    };
  },

  methods: {
    formErase(){
      
      (this.$refs["resetForm"] as any).click();
     
    },
    // eslint-disable-next-line prettier/prettier
    callAddUser(id,orgType)
    {
     this.orgId=id
     this.orgType=orgType.toLowerCase()
     
     console.log("ORG TYPE",orgType)
    },
    checkRole() {
      // console.log("check role",this.role)
      if (this.orgType == "Consumer") this.orgType = "C";
      else if (this.orgType == "Manufacturer") this.orgType = "M";
      else if (this.orgType == "Provider") this.orgType = "P";
      else if (this.orgType == "1SB") this.role = "P";
      return this.orgType;
    },
    orgRole() {
      if (this.role == "Consumer") this.role = "C";
      else if (this.role == "Manufacturer") this.role = "M";
      else if (this.role == "Provider") this.role = "P";
      else if (this.role == "1SB") this.role = "P";
      return this.role;
    },
   
    addData(formData) {
      
      this.loading=true
      this.buttonActive=true
      formData.organizationType = this.orgRole();
      formData.organizationId = this.globalData.organizationId;
      formData.data.orgRole =this.orgType;
      formData.data.roleType= "Admin"
      formData.data.organizationId = this.orgId;

      ApiService.post("/user", formData)
        .then((data) => {
          this.loading=false,
          this.buttonActive=false,
          (this.$refs["resetForm"] as any).click();
              hideModal(this.addCustomerModalRef);
              this.$emit("changeTitle", "false");
             ElNotification({
            title: 'Success',
            message: 'New User added!',
            type: 'success',
            duration: 2000,
            position: 'top-right'
          })
              
          
        })
        .catch((error) => {
          this.loading=false
          this.buttonActive=false
         ElNotification({
            title: 'Error',
            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']) ,
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })
        });
    },
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addCustomerModalRef = ref<null | HTMLElement>(null);
    // const loading = ref<boolean>(false);
    
    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        orgRole: "",
        organizationId: "",
        name: "",
        email: "",
        mobileNumber: "",
        roleType: "",
      },
    });

    const formValidation = Yup.object().shape({
      name: Yup.string()
        .max(45, "Name can be 45 characters")
        .matches(NAME, "Name should be only characters")
        .required()
        .label("Name"),
      email: Yup.string()
        .email("email must contain @ and . or invalid email address")
        .required()
        .label("Email"),
      // role: Yup.string().required().label("Role"),
     
      mobileNumber: Yup.string()
        .min(10, "Mobile number should be exactly 10 digits")
        .max(10, "Mobile number should be exactly 10 digits")
        .matches(PHONE, "Mobile number must be only digits")
        .required()
        .label("Mobile Number"),
    });
    

    return {
      formData,
      formValidation,
      formRef,
      addCustomerModalRef,
    };
  },
});
