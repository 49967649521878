
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddOrganizationModal from "@/components/modals/forms/AddOrganizationModal.vue";
import EditOrganizationModal from "@/components/modals/forms/EditOrganizationModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElLoading } from "element-plus";
import { ElNotification } from "element-plus";
import AddUserModal from "@/components/modals/forms/AddUserModal.vue";
import router from "@/router"

interface ICustomer {
  id: number;
  name: string;
  panNo: string;
  organizationType: string;
  industry: string;
  email: string;
  uid: string;
  status: string;
}

export default defineComponent({
  name: "ManageOrganization",
  components: {
    Datatable,
    AddOrganizationModal,
    EditOrganizationModal,
  },
  data() {
    return {
      organizationdata: [],
      flag: false,
      flag1:false,
      organizationid: String,
      editflag: false,
      initOrganization: [] as ICustomer[],
      search: "",
      buttonActive: false,
      searchFlag: true,
    };
  },
  methods: {
    searchItems() {
      this.organizationdata["data"].splice(
        0,
        this.organizationdata["data"].length,
        ...this.initOrganization
      );
      if (this.search !== "") {
        let results = [] as ICustomer[];
        for (let j = 0; j < this.organizationdata["data"].length; j++) {
          if (
            this.searchingFunc(this.organizationdata["data"][j], this.search)
          ) {
            results.push(this.organizationdata["data"][j]);
          }
        }
        this.organizationdata["data"].splice(
          0,
          this.organizationdata["data"].length,
          ...results
        );
        if (
          this.organizationdata["data"].length == 0 &&
          this.searchFlag == true
        ) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "Record not found!",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.organizationdata["data"].length != 0) {
          this.searchFlag = true;
        }
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    displayData(data) {
      this.organizationdata = JSON.parse(JSON.stringify(data));
      
      if(this.organizationdata.length==0)
      {
        this.flag=false
        this.flag1=true
      }
      else
      {
        this.flag = true
        this.flag1= false
      }
      this.initOrganization.splice(
        0,
        this.organizationdata["data"].length,
        ...this.organizationdata["data"]
      );
    },
    getorganizations() {
      this.flag = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get("/organization")
        .then((data) => {
          loading.close();
          this.displayData(data.data);
        })
        .catch((error) => {
          loading.close();
          console.log()
          this.displayData([]) 
          ElNotification({
            title: "Warning",
            message: "No Data in Organization",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
          // if(error.response.status===401)
          //  {
          //     router.push({ name: "sign-in" });
          //  }
         
        });
    },
    editorganization(id, companyName, panNo, industry, email,orgType, allowedIP, allowedDomain) {
      (this.$refs["editOrganizationForm"] as any).callUpdate(
        id,
        companyName,
        panNo,
        industry,
        email,
        orgType, 
        allowedIP, 
        allowedDomain
      );
      this.buttonActive=true
    },
    ChangeT(test) {
      this.getorganizations();
    },
    buttonReset(reset)
    {
      this.buttonActive=false
      console.log("hello")
    }
  },
  mounted() {
    this.getorganizations();
  },
  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Organization Id",
        key: "id",
        sortable: true,
      },
      {
        name: "Company",
        key: "name",
        sortable: true,
      },
      {
        name: "PAN Number",
        key: "panNo",
        sortable: true,
      },
      {
        name: "Org Type",
        key: "organizationType",
        sortable: true,
      },
      {
        name: "Industry",
        key: "industry",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },

      {
        name: "STATUS",
        key: "status",
        sortable: true,
      },
      {
        name: "Action",
        key: "actions",
      },
    ]);

    let customers = ref<Array<ICustomer>>([]);

    onMounted(() => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Organizations", ["Manage"]);
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i].id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    return {
      customers,
      tableHeader,
      deleteCustomer,
      checkedCustomers,
      deleteFewCustomers,
    };
  },
});
