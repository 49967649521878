
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { Field, Form } from "vee-validate";
import { PAN, COMPANYNAME, INDUSTRY } from "@/helper/globalConst";
import AddAdminUserModal from "@/components/modals/forms/AddAdminUserModal.vue";
import { ElNotification } from "element-plus";
import * as Yup from "yup";
export default defineComponent({
  name: "add-customer-modal",
  mixins: [Globals],
  components: {
    Form,
    Field,
    AddAdminUserModal,
  },
  props: {
    organizationid: String,
  },
  data() {
    return {
      id: "",
      radio:"",
      companyName: "",
      panNo: "",
      industry: "",
      email: "",
      role: this.getOrgRole(),
      orgrole: "",
      loading: false,
      buttonActive: true,
      userModel: false,
      orgType:"",
      ips: [],
      ip_error: "",
      domains: [],
      ip:"",
      domain:""
    };
  },
  methods: {
    validate_ips(inputValue){
      const regexPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
      return regexPattern.test(inputValue);
    },
    addIP(event) {
      event.preventDefault()
      let val = event.target.value.trim()
      if (val.length > 0) {
        if (this.ips.length >= 1) {
          for (let i = 0; i < this.ips.length; i++) {
            if (this.ips[i] === val) {
              return false
            }
          }
        }
        if (this.validate_ips(val)){
          this.ips.push(val);
          this.formData.data['allowedIP'] = this.ips.join(",");
          this.ip_error = ""
          event.target.value = '';
        }
        else{
          console.log("error invalid data enterd")
          this.ip_error = "Invalid IP."
          return false
        }
        console.log(this.ips);
        
        
        console.log("data-->>", this.formData.data);
      }
    },
    removeIP(index) {
      this.ips.splice(index, 1);
      this.formData.data['allowedIP'] = this.ips.join(",");
    },
    removeLastIP(event) {
      if (event.target.value.length === 0) {
        this.removeIP(this.ips.length - 1)
      }
    },

    radioFun(){
     
      if(this.radio=="yes")
      {
        this.userModel=true
      }
      else if(this.radio=="no")
      {
        this.userModel=false
      }
      this.buttonActive=false

    },
    buttonReset() {
      this.ips.length=0;
      this.ip="";
      this.domain="";
      this.domains.length =0;
      this.buttonActive=true
      this.radio=""
      this.$emit("changeButton", "false");
     

      
    
    },
    isUpdated()
    {
      this.buttonActive=false
    },
    formReset() {
      this.id = "";
      this.companyName = "";
      this.panNo = "";
      this.industry = "";
      this.email = "";
      this.ips = [];
      this.domains = [];
    },
    callUpdate(id, companyName, panNo, industry, email ,orgType, ips, domains) {
      this.id = id;
      this.companyName = companyName;
      this.panNo = panNo;
      this.industry = industry;
      this.email = email;
      this.orgType= orgType;
      if (ips && ips != ""){
        this.ips = ips.split(",");
      }
      if (domains && domains != ""){
        this.domains = domains.split(",");
      }
    },
    checkRole() {
      if (this.role == "consumer") this.role = "C";
      else if (this.role == "manufacture") this.role = "M";
      else if (this.role == "api_provider") this.role = "P";
      else if (this.role == "1SB") this.role = "P";
      return this.role;
    },
    editOrganization(formData) {
      if (this.userModel == true){
        (this.$refs["addUserForm"] as any).callAddUser(
          this.id,
          this.orgType
        );
      }
      this.loading = true;
      this.buttonActive = true;
      formData.organizationType = this.checkRole();
      formData.organizationId = this.globalData.organizationId;

      formData.data.id = this.id;
      formData.data.name = this.companyName;
      formData.data.panNo = this.panNo;
      formData.data.industry = this.industry;
      formData.data.email = this.email;

      ApiService.put("/organization", formData)
        .then((data) => {
          this.loading = false;
          this.buttonActive = false;
          this.radio=""
          hideModal(this.editOrganizationModalRef);
          this.$emit("changeTitle", "false");
           this.$emit("changeButton", "false");
           (this.$refs["callAddModel"] as any).click();
       
          ElNotification({
            title: "Success",
            message: "Organization Updated!",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
         
        })
        .catch((error) => {
          this.loading = false;
          this.buttonActive = false;
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        });
    },
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const editOrganizationModalRef = ref<null | HTMLElement>(null);
    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        id: "",
        name: "",
        panNo: "",
        industry: "",
        email: "",
      },
    });

    const formValidation = Yup.object().shape({
      companyName: Yup.string()
        .max(45, "Company name should be atmost 45 alphanumeric")
        .matches(COMPANYNAME, "Company name should be alphanumeric")
        .required()
        .label("Company name"),
      panNumber: Yup.string()
        .min(10, "PAN number should be exactly 10 alphanumeric")
        .max(10, "PAN number should be exactly 10 alphanumeric")
        .matches(PAN, "PAN number must be alphanumeric")
        .required()
        .label("PAN Number"),
      email: Yup.string()
        .email("email must contain @ and . or invalid email address")
        .required()
        .label("Email"),
      industry: Yup.string()
        .max(45, "Industry name should be atmost 45 alphanumeric")
        .matches(INDUSTRY, "Industry name should be alphanumeric")
        .required()
        .label("Industry name"),
    });

    return {
      formData,
      formValidation,
      formRef,
      editOrganizationModalRef,
    };
  },
});
